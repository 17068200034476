jQuery(function($) {


    $( document ).ready(function() {
        console.log('READY');
      
      // Remove the ubermenu-active class when the mouse leaves that element.
      if($('.ubermenu-item')) {
        console.log('Has ubermenu-item');
        $('.ubermenu-item').mouseleave(function(){
          console.log('mouse is leaving ubermenu-item. Remove the the ubermenu-active class.');
          $(this).toggleClass('ubermenu-active');
        });
      }
        $('p:empty').remove();
        var eBody = $('body'); // The pages body element


        eBody.addClass('.tk-jaf-bernino-sans'); // Add the TypeKit font to the all pages.
        //eBody.addClass('.tk-jaf-bernino-sans-condensed'); // Add the TypeKit font to the all pages.

        if(eBody.hasClass('logged-in')) {
            // Move the top of the page down to account for the admin bar
            //eBody.css('margin-top', '47px');
        }

        /*
          Textbox Select on Focus (Add this to theme base)
        */
        $(':text').focus(function(){
          $(this).one('mouseup', function(event){
              event.preventDefault();
          }).select();
        });

      /*
        Image alignment and wrapping fix
      */
      if($('img').length) {
        $('img').each(function(){
          if($(this).hasClass('alignright')) {
            // Add right align attribute
            $(this).attr('align','right');
          } else if ($(this).hasClass('alignleft')) {
            $(this).attr('align', 'left');
          }
        });
      }
      /*
        Drop-Down Navigation
      */
      if($('#menu')) {
        $('.menu-item-has-children').mouseover(function() {
          console.log('Parent Hover');
          $('.menu-item-has-children.ul').css('display', 'inline-block');
        });
      }

      if($('.ubsc-media')) {
          // Has ubermenu media box
          if($('.ubsc-media > a')) {
            // Has an anchor tag, force a fancybox-media class onto it.
            var anchor = $('.ubsc-media > a');
            anchor.addClass('fancybox-media');
          }

      }

      /*
        Slide Toggles
      */
      $('.toggle').click(function() {

        $(this).toggleClass('toggle-active'); // switch the active
        $(this).next().slideToggle('fast'); // open the toggle box

        return false;
      });

      /*
        Service & Support
      */
      // Form
      if($('#input_3_1')) {
        /*
          Service & Suppor form filtering flow option.

          We only need to wait for the product category select box to change. When changed
          we'll pull the information from the other boxes and run the conditional statements
          against all the selected values.
        */
        // Location select box
        console.log('Service & Support');
        var locationInput = $('#input_3_1');
        var productInput = $('#input_3_2');
        var productCatInput = $('#input_3_4');
        var contactInput = $('#input_3_3');

        // Disable / Hide the submit button
        $('#gform_submit_button_3').css('display', 'none');
        productCatInput.change(function() {
          console.log('Category Changed');


          // Get the location value
          var locationText = $('#input_3_1 option:selected').text();
          // Get the main product area
          var productText = $('#input_3_2 option:selected').text();
          // Get the product category
          var productCatText = $('#input_3_4 option:selected').text();
          // Get the contact email array
          var contactEmails = getContactEmails(locationText, productText, productCatText);

          // Clear the contact select box
          contactInput.html('');
          // Add the contact email to the contact select box
          addItem(contactInput, "Select Email");
          addItem(contactInput, contactEmails);
          console.log('Location: ' + locationText);
          console.log('Product: ' + productText);
          console.log('Product Cat: ' + productCatText);
          console.log('Email: ' + contactEmails);

          console.log('Disabling Email Box');
          $('#input_3_7').prop( "disabled", true);
          $('.gform_body').css('height', '825px');
        }); // productCatInput.change()

        contactInput.change(function() {
          console.log($('#input_3_3 option:selected').text());
          $('#input_3_7:text').val($('#input_3_3 option:selected').text());
          // Unhide the submit button
          $('#gform_submit_button_3').css('display', 'inline-block');
        }); //contactInput.change()

            /*
            Bolivia
Chile
China
Columbia
Europe
India
Latin America
Mexico
Nigeria
North America
Peru
Russia and CIS
Thailand
Other

            */

      }



      function addItem(element, name, value = null) {
        console.log('addItem | ' + element + name + value);
        // Set the value to the name if it's NULL
        if(!value) value = name;

        // Add the requested option to the element
        var appendHTML = '<option value="' + value + '">' + name + '</option>';
        element.append(appendHTML);

      }

        // Slicknav Setup

	    $('#menu').slicknav();
      /*
        Products Page
      */
      if($('.products').length) {
        $('#submission-confirm').delay(4000).fadeOut("slow");
        /*
          Check for ratings
        */
        if($('.wprs_user_rating')) {
          /*
            Product has ratings
          */
          var elementCount = 0;
          var iterationCount = 0;
          $('.rating-user').each(function(){
            /*
              Loop over each WPRS rating star and insert into the review rating star block at the bottom of the page.
            */
            if(iterationCount != 1) {
              /*
                There are some duplicates produced by the plugin that we must skip over.
                Add an || check to the iterationCount on any iteration needing skipped.
              */
              var detachedElement = $(this).detach();
              var placementElement;
              if(elementCount == 0) {
                /*
                  This is the first element and needs to be applied to the overall review area
                */
                placementElement = $('.product-reviews header div:first-of-type');
                detachedElement.prependTo(placementElement);

              } else {
                var placementElement = $('.review-item:nth-of-type(' + elementCount + ') section:first-of-type');
                detachedElement.appendTo(placementElement);
              }


              elementCount++;
            } // // if(iterationCount)

            iterationCount++;

          });
        }
        // Review Toggle Box
        $('.toggle').each(function(){
      		$(this).find('.toggle-content').hide();
      	});

      	// When a toggle is clicked (activated) show their content
      	$('.toggle a.toggle-trigger').click(function(){
      		var el = $(this), parent = el.closest('.toggle');

      		if( el.hasClass('active') )
      		{
      			parent.find('.toggle-content').slideToggle();
      			el.removeClass('active');
      		}
      		else
      		{
      			parent.find('.toggle-content').slideToggle();
      			el.addClass('active');
      		}
      		return false;
      	}); // End Review Toggle Box
      } // End Product Page


      if($('.fancybox-inner').length) {
        // Fancybox
        console.log('Fancybox');
        var screenHeight = $(document).height() - 40;
        $('.fancybox-inner').css('max-height', screenHeight);
      }
        if($('.home').length) {
            // Home page
            console.log('HOME');

            $('.category-card-container').mouseenter(function() {

                var ID = $(this).attr('id');
                var HoverElement = $('#hover-' + ID);
                var ImageElement = $('#image-' + ID);
                clearTimeout($(this).data('timeoutID'));
                //console.log('Entering Container');
                if($(HoverElement).length) {
                    console.log('Hover');
                    $(HoverElement).css('display', 'block');
                    //$(HoverElement).fadeIn("fast");
                    $(ImageElement).css('display', 'none');
                }


            }).fadeIn("slow").mouseleave(function() {
                var ID = $(this).attr('id');
                 var HoverElement = $('#hover-' + ID);
                var ImageElement = $('#image-' + ID);
                //console.log('Leaving Container');
                var someElement = $(this),
                    timeoutID = setTimeout(function() {
                        //$(HoverElement).fadeOut("slow");

                        $(HoverElement).css('display', 'none');
                        $(ImageElement).css('display', 'block');
                    }, 650);

                    someElement.data("timeoutID", timeoutID);
            }).fadeIn("slow");



        } // End Home Page

        // Page-Gallery List
        if($('.page-gallery').length) {
            // Page w/ Gallery
            console.log('Page w/ Gallery');
            if($('.gallery').length) {
                console.log('gallery');
                if($('.gallery-icon').length) {
                    console.log('icon');
                    if($('.gallery-icon img').length) {
                        console.log('HAS IMG');
                        var Image = $('.gallery-icon img');
                        //Image.addClass('fancybox');
                        //Image.attr('rel', 'gallery1');

                        Image.click(function() {
                            //console.log('Image Clicked');
                            // unhide the image after X seconds
                            setTimeout(function() {

                                //console.log("Showing Image");
                                Image.css('display', 'initial');
                            }, 650);

                        });
                    }
                }
            }
        }


        // Product Page
        if($('.products').length) {
            // Product Page
            //console.log('PRODUCT PAGE');
            if($('.gallery').length) {
                //console.log('HAS GALLERY');
                if($('.gallery-icon').length) {
                    console.log('HAS ICONS');
                    // We need to append the fancybox classes to the individual images.
                    if($('.gallery-icon img').length) {
                        console.log('HAS IMG');
                        var Image = $('.gallery-icon img');
                        //Image.addClass('fancybox');
                        Image.attr('rel', 'gallery1');
                        console.log(Image.attr('src'));
                        //Image.wrap('<a href="?img=' + Image.src + '#image"></a>');

                        Image.each(function() {
                          var src = $(this).attr('src');
                          //$(this).wrap('<a href="?img=' + src + '#image"></a>');
                        });
                        Image.click(function() {
                          // return this.href + '&cylnders=12';
                          var image = this.src;
                          console.log(image);
                          $('#gallery-image-popup').src = image;
                          console.log($('#gallery-image-popup').src);
                          console.log('Gallery Image Clicked');
                            //console.log('Image Clicked');
                            // unhide the image after X seconds
                            setTimeout(function() {
                              console.log('Displaying Click Image Thumb');
                                //console.log("Showing Image");
                                //Image.css('display', 'inline-block');
                            }, 650);

                        });
                    }

                }
                // Gallery Size Alignments
                adjustGallery('product'); // Moves the with to match the right offset of the content box.
                $('.imp-wrap').css('width', '100%');
                $(window).resize(function(){
                   adjustGallery('product'); // Moves the width to match the right offset of the content box.
                   $('.imp-wrap').css('width', '100%');
                });
                // End Gallery Size Alignments

                // On hover gallery image effect
                // Reset the timeout when we enter the gallery containing box.
                // var galleryContainer = $('.gallery');
                // var productContent = $('.product-content');
                // productContent.mouseenter(function() {
                //     clearTimeout($(this).data('timeoutID'));
                //     console.log('ENTER : Product Content');
                // }).mouseleave(function() {
                //     console.log('LEAVING : Product Content');
                //     var someElement = $(this),
                //         timeoutID = setTimeout(function() {
                //             console.log("Hiding Container");
                //             hoverGallery('product');
                //         }, 650);

                //         someElement.data("timeoutID", timeoutID);
                // });
                // galleryContainer.mouseenter(function() {
                //     clearTimeout($(this).data('timeoutID'));
                //     console.log('Entering Container');
                // }).mouseleave(function() {
                //     console.log('Leaving Container');
                //     var someElement = $(this),
                //         timeoutID = setTimeout(function() {
                //             console.log("Hiding Container");
                //             hoverGallery('product');
                //         }, 650);

                //         someElement.data("timeoutID", timeoutID);
                // });


                // $('.gallery-icon').click(function() {
                //     console.log('Gallery Thumb Enter');
                //     var Image = $(this).find("img");
                //     var ImageSRC = Image.attr('src');
                //     console.log(ImageSRC);
                //     var filePathFull =  getOriginalImage(ImageSRC);
                //     console.log(filePathFull);
                //     var shapeContainer = $('.imp-shape-container'); // Holds the number shapes
                //     var mainImage = $('.imp-wrap img'); // the main image
                //     var mainImageContainer = $('.imp-wrap'); // container for the image
                //     var galleryContainer = $('.gallery');

                //     shapeContainer.css('display', 'none');
                //     mainImage.css('display', 'none');
                //     console.log('Adding Hover Image');
                //     $('#gallery-hover-image').remove();
                //     mainImageContainer.append("<img src=" + filePathFull + " class=\"FadeIn\" id=\"gallery-hover-image\">" );

                // });

            }
        }

        function getOriginalImage(ImageSRC) {
            // Break the image src up into seperate parts by directory
            var ImageStructure = ImageSRC.split("/");

            // The file name should be the last entry
            var fileName = ImageStructure[ImageStructure.length - 1];
            console.log(fileName);

            // We need to remove the dimensions that Wordpress applies to the end of the fileame
            var fileNameParts = fileName.split("-");
            var fileEXT = fileNameParts[fileNameParts.length - 1].split(".");
            fileEXT = fileEXT[1];
            console.log(fileEXT);
            var fileNameFull = '';
            for(var i = 0; i < fileNameParts.length - 1; i++) {
                if(fileNameFull != '') {
                    fileNameFull += '-' + fileNameParts[i];
                } else {
                    fileNameFull = fileNameParts[i];
                }

            }
            fileNameFull += '.' + fileEXT;

            console.log(fileNameFull);
            var filePathFull = '';
            // We need to add the filename back to the structure
            for(var i = 0; i < ImageStructure.length - 1; i++) {
                if(filePathFull != '') {
                    filePathFull += '/' + ImageStructure[i];
                } else {
                    filePathFull = ImageStructure[i];
                }
            }

            filePathFull += '/' + fileNameFull;

            return filePathFull;
        }
        function hoverGallery(page) {
            if(page == 'product') {
                var shapeContainer = $('.imp-shape-container'); // Holds the number shapes
                var mainImage = $('.imp-wrap img'); // the main image
                var mainImageContainer = $('.imp-wrap'); // container for the image
                var galleryContainer = $('.gallery');

                // Find out what is being shown
                if($('#gallery-hover-image').length) {
                    // Hover image is being shown
                    // Remove the hover image and display the main image
                    shapeContainer.css('display', 'initial');
                    mainImage.css('display', 'initial');
                    $('#gallery-hover-image').remove();

                    console.log('Hover Image Shown');
                } else {
                    console.log('Main Image Shown');
                    shapeContainer.css('display', 'initial');
                    mainImage.css('display', 'initial');
                    $('#gallery-hover-image').remove();
                }
            } else {
                var mainImage = $('.gallery-image img');
                var mainImageContainer = $('.gallery-image');

                if($('#gallery-hover-image').length) {
                    mainImage.css('display', 'initial');
                    $('#gallery-hover-image').remove();
                } else {
                    mainImage.css('display', 'initial');
                    $('#gallery-hover-image').remove();
                }
            }

        }
        function changeGallery(stage, image = null) {
            var shapeContainer = $('.imp-shape-container'); // Holds the number shapes
            var mainImage = $('.imp-wrap img'); // the main image
            var mainImageContainer = $('.imp-wrap'); // container for the image
            var galleryContainer = $('.gallery');

            // if($('#gallery-hover-display').length) {
            //     console.log('Hover Display Exists');
            //     $('#gallery-hover-display').remove();
            // }


            if(stage == 'original') {
                // Change back to the original image for this product

                shapeContainer.css('display', 'initial');
                mainImage.css('display', 'initial');
                if($('#gallery-hover-display').length) {

                    $('#gallery-hover-display').remove();
                    console.log('REVERT BACK');
                }
            } else {
                // Change to the most recently hovered image.
                console.log('Changing Main Image');
                // Remove an previous existance of the changed element
                if($('#gallery-hover-display').length) {
                    $('#gallery-hover-display').remove();
                }
                var imageSRC = image.attr('src');
                mainImageContainer.append("<img src=" + imageSRC + " class=\"FadeIn\" id=\"gallery-hover-display\">" );
                shapeContainer.css('display', 'none');
                mainImage.css('display', 'none');
            }
        }

        function adjustGallery(page) {
            if(page == 'product') {
                var Content = $('.product-content');
            } else {
                var Content = $('.gallery-information');
            }
            //var productContent = $('.product-content');
            var Gallery = $('.product-gallery');
            var contentRightOffset = Content.offset().left + Content.outerWidth();
            var galleryRightOffset = Gallery.offset().left + Gallery.outerWidth();
            var iteration = 0;

            // Loop until productGalleries right offset is within 1px of the productContent right offset
            if(contentRightOffset > galleryRightOffset) {
                 do {
                    var newWidth = Gallery.width() + 1;

                    Gallery.css('width', newWidth);
                    galleryRightOffset = Gallery.offset().left + Gallery.outerWidth();
                    var comparer = contentRightOffset - galleryRightOffset;
                    // Safety break
                    iteration++;
                    if(iteration > 1000) break;
                } while (comparer > 1);
            } else {
                 do {
                    var newWidth = Gallery.width() - 1;

                    Gallery.css('width', newWidth);
                    galleryRightOffset = Gallery.offset().left + Gallery.outerWidth();
                    var comparer = contentRightOffset - galleryRightOffset;
                    // Safety break
                    iteration++;
                    if(iteration > 1000) break;
                } while (comparer < 1);
            }

        }

        /*
			 *  Simple image gallery. Uses default settings
			 */

			$('.fancybox').fancybox();

			/*
			 *  Different effects
			 */
            $(".fancybox-gallery").fancybox({
                openEffect : 'fade',
                closeEffect : 'fade'
            });
			// Change title type, overlay closing speed
			$(".fancybox-effects-a").fancybox({
				helpers: {
					title : {
						type : 'outside'
					},
					overlay : {
						speedOut : 0
					}
				}
			});

			// Disable opening and closing animations, change title type
			$(".fancybox-effects-b").fancybox({
				openEffect  : 'none',
				closeEffect	: 'none',

				helpers : {
					title : {
						type : 'over'
					}
				}
			});

			// Set custom style, close if clicked, change title type and overlay color
			$(".fancybox-effects-c").fancybox({
				wrapCSS    : 'fancybox-custom',
				closeClick : true,

				openEffect : 'none',

				helpers : {
					title : {
						type : 'inside'
					},
					overlay : {
						css : {
							'background' : 'rgba(238,238,238,0.85)'
						}
					}
				}
			});

			// Remove padding, set opening and closing animations, close if clicked and disable overlay
			$(".fancybox-effects-d").fancybox({
				padding: 0,

				openEffect : 'elastic',
				openSpeed  : 150,

				closeEffect : 'elastic',
				closeSpeed  : 150,

				closeClick : true,

				helpers : {
					overlay : null
				}
			});

			/*
			 *  Button helper. Disable animations, hide close button, change title type and content
			 */

			$('.fancybox-buttons').fancybox({
				openEffect  : 'none',
				closeEffect : 'none',

				prevEffect : 'none',
				nextEffect : 'none',

				closeBtn  : false,

				helpers : {
					title : {
						type : 'inside'
					},
					buttons	: {}
				},

				afterLoad : function() {
					this.title = 'Image ' + (this.index + 1) + ' of ' + this.group.length + (this.title ? ' - ' + this.title : '');
				}
			});


			/*
			 *  Thumbnail helper. Disable animations, hide close button, arrows and slide to next gallery item if clicked
			 */

			$('.fancybox-thumbs').fancybox({
				prevEffect : 'none',
				nextEffect : 'none',

				closeBtn  : false,
				arrows    : false,
				nextClick : true,

				helpers : {
					thumbs : {
						width  : 50,
						height : 50
					}
				}
			});

			/*
			 *  Media helper. Group items, disable animations, hide arrows, enable media and button helpers.
			*/
			$('.fancybox-media')
				.attr('rel', 'media-gallery')
				.fancybox({
                    width: 720,
                    height: 480,
                    type: 'iframe',
                    autoScale: true,
					openEffect : 'fade',
					closeEffect : 'fade',
					prevEffect : 'none',
					nextEffect : 'none',
                    centerOnScroll: true,
                    autoCenter: true,
					arrows : false,
					helpers : {
						media : {},
						buttons : {}
					}
				});

			/*
			 *  Open manually
			 */

			$("#fancybox-manual-a").click(function() {
				$.fancybox.open('1_b.jpg');
			});

			$("#fancybox-manual-b").click(function() {
				$.fancybox.open({
					href : 'iframe.html',
					type : 'iframe',
					padding : 5
				});
			});

			$("#fancybox-manual-c").click(function() {
				$.fancybox.open([
					{
						href : '1_b.jpg',
						title : 'My title'
					}, {
						href : '2_b.jpg',
						title : '2nd title'
					}, {
						href : '3_b.jpg'
					}
				], {
					helpers : {
						thumbs : {
							width: 75,
							height: 50
						}
					}
				});
			});

      function getContactEmails(location, product, category) {

        switch(location) {
          case "Bolivia":
            switch(product) {
              case "Pavement Preservation":
                switch(category) {
                  case "Truck-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Pre-Mixed Applicators":
                    return "service@bergkampinc.com";
                    break;
                  case "Spreader Box":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                } // switch(category)
                break;
              case "Patching Maintenance":
                switch(category) {
                  case "All-in-one Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Spray Injection Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Truck-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                }
                break;
              case "Emulsion":
                case "Emulsion Mills":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Milss":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Batch Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Continuous Pavers":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Crumb Rubber Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Laboratory Research Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                default:
                  return "servicebbs@bergkampinc.com";
                  break;
              } // switch(product)
            break; // Bolivia
          case "Chile":
            switch(product) {
              case "Pavement Preservation":
                switch(category) {
                  case "Truck-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Pre-Mixed Applicators":
                    return "service@bergkampinc.com";
                    break;
                  case "Spreader Box":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                } // switch(category)
                break;
              case "Patching Maintenance":
                switch(category) {
                  case "All-in-one Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Spray Injection Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Truck-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                }
                break;
              case "Emulsion":
                case "Emulsion Mills":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Milss":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Batch Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Continuous Pavers":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Crumb Rubber Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Laboratory Research Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                default:
                  return "servicebbs@bergkampinc.com";
                  break;
              } // switch(product)
            break; // Chile
          case 'China':
          switch(product) {
            case "Pavement Preservation":
              switch(category) {
                case "Truck-Mounted Pavers":
                  return "service@bergkampinc.com";
                  break;
                case "Trailer-Mounted Pavers":
                  return "service@bergkampinc.com";
                  break;
                case "Pre-Mixed Applicators":
                  return "service@bergkampinc.com";
                  break;
                case "Spreader Box":
                  return "service@bergkampinc.com";
                  break;
                case "Optional Equipment":
                  return "service@bergkampinc.com";
                  break;
                default:
                  return "service@bergkampinc.com";
                  break;
              } // switch(category)
              break;
            case "Patching Maintenance":
              switch(category) {
                case "All-in-one Pothole Patchers":
                  return "service@bergkampinc.com";
                  break;
                case "Spray Injection Pothole Patchers":
                  return "service@bergkampinc.com";
                  break;
                case "Truck-Mounted Patchers":
                  return "service@bergkampinc.com";
                  break;
                case "Trailer-Mounted Patchers":
                  return "service@bergkampinc.com";
                  break;
                case "Optional Equipment":
                  return "service@bergkampinc.com";
                  break;
                default:
                  return "service@bergkampinc.com";
                  break;
              }
              break;
            case "Emulsion":
              case "Emulsion Mills":
                return "servicebbs@bergkampinc.com";
                break;
              case "Polymer Modified Asphalt Milss":
                return "servicebbs@bergkampinc.com";
                break;
              case "Emulsion Batch Plants":
                return "servicebbs@bergkampinc.com";
                break;
              case "Emulsion Continuous Pavers":
                return "servicebbs@bergkampinc.com";
                break;
              case "Polymer Modified Asphalt Plants":
                return "servicebbs@bergkampinc.com";
                break;
              case "Crumb Rubber Modified Asphalt Plants":
                return "servicebbs@bergkampinc.com";
                break;
              case "Laboratory Research Plants":
                return "servicebbs@bergkampinc.com";
                break;
              default:
                return "servicebbs@bergkampinc.com";
                break;
            } // switch(product)
          break; // China
          case "Columbia":
            switch(product) {
              case "Pavement Preservation":
                switch(category) {
                  case "Truck-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Pre-Mixed Applicators":
                    return "service@bergkampinc.com";
                    break;
                  case "Spreader Box":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                } // switch(category)
                break;
              case "Patching Maintenance":
                switch(category) {
                  case "All-in-one Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Spray Injection Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Truck-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                }
                break;
              case "Emulsion":
                case "Emulsion Mills":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Milss":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Batch Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Continuous Pavers":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Crumb Rubber Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Laboratory Research Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                default:
                  return "servicebbs@bergkampinc.com";
                  break;
              } // switch(product)
            break; // Columbia
          case "Europe":
            switch(product) {
              case "Pavement Preservation":
                switch(category) {
                  case "Truck-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Pre-Mixed Applicators":
                    return "service@bergkampinc.com";
                    break;
                  case "Spreader Box":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                } // switch(category)
                break;
              case "Patching Maintenance":
                switch(category) {
                  case "All-in-one Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Spray Injection Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Truck-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                }
                break;
              case "Emulsion":
                case "Emulsion Mills":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Milss":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Batch Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Continuous Pavers":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Crumb Rubber Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Laboratory Research Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                default:
                  return "servicebbs@bergkampinc.com";
                  break;
              } // switch(product)
            break; // Europe
          case "India":
            switch(product) {
              case "Pavement Preservation":
                switch(category) {
                  case "Truck-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Pre-Mixed Applicators":
                    return "service@bergkampinc.com";
                    break;
                  case "Spreader Box":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                } // switch(category)
                break;
              case "Patching Maintenance":
                switch(category) {
                  case "All-in-one Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Spray Injection Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Truck-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                }
                break;
              case "Emulsion":
                case "Emulsion Mills":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Milss":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Batch Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Continuous Pavers":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Crumb Rubber Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Laboratory Research Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                default:
                  return "servicebbs@bergkampinc.com";
                  break;
              } // switch(product)
            break; // India
          case "Latin America":
            switch(product) {
              case "Pavement Preservation":
                switch(category) {
                  case "Truck-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Pre-Mixed Applicators":
                    return "service@bergkampinc.com";
                    break;
                  case "Spreader Box":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                } // switch(category)
                break;
              case "Patching Maintenance":
                switch(category) {
                  case "All-in-one Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Spray Injection Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Truck-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                }
                break;
              case "Emulsion":
                case "Emulsion Mills":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Milss":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Batch Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Continuous Pavers":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Crumb Rubber Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Laboratory Research Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                default:
                  return "servicebbs@bergkampinc.com";
                  break;
              } // switch(product)
            break; // Latin America
          case "Mexico":
            switch(product) {
              case "Pavement Preservation":
                switch(category) {
                  case "Truck-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Pre-Mixed Applicators":
                    return "service@bergkampinc.com";
                    break;
                  case "Spreader Box":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                } // switch(category)
                break;
              case "Patching Maintenance":
                switch(category) {
                  case "All-in-one Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Spray Injection Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Truck-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                }
                break;
              case "Emulsion":
                case "Emulsion Mills":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Milss":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Batch Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Continuous Pavers":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Crumb Rubber Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Laboratory Research Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                default:
                  return "servicebbs@bergkampinc.com";
                  break;
              } // switch(product)
            break; // Mexico
          case "Nigeria":
            switch(product) {
              case "Pavement Preservation":
                switch(category) {
                  case "Truck-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Pre-Mixed Applicators":
                    return "service@bergkampinc.com";
                    break;
                  case "Spreader Box":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                } // switch(category)
                break;
              case "Patching Maintenance":
                switch(category) {
                  case "All-in-one Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Spray Injection Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Truck-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                }
                break;
              case "Emulsion":
                case "Emulsion Mills":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Milss":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Batch Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Continuous Pavers":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Crumb Rubber Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Laboratory Research Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                default:
                  return "servicebbs@bergkampinc.com";
                  break;
              } // switch(product)
            break; // Nigeria
          case "North America":
            switch(product) {
              case "Pavement Preservation":
                switch(category) {
                  case "Truck-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Pre-Mixed Applicators":
                    return "service@bergkampinc.com";
                    break;
                  case "Spreader Box":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                } // switch(category)
                break;
              case "Patching Maintenance":
                switch(category) {
                  case "All-in-one Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Spray Injection Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Truck-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                }
                break;
              case "Emulsion":
                case "Emulsion Mills":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Milss":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Batch Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Continuous Pavers":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Crumb Rubber Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Laboratory Research Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                default:
                  return "servicebbs@bergkampinc.com";
                  break;
              } // switch(product)
            break; // North America
          case "Peru":
            switch(product) {
              case "Pavement Preservation":
                switch(category) {
                  case "Truck-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Pre-Mixed Applicators":
                    return "service@bergkampinc.com";
                    break;
                  case "Spreader Box":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                } // switch(category)
                break;
              case "Patching Maintenance":
                switch(category) {
                  case "All-in-one Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Spray Injection Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Truck-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                }
                break;
              case "Emulsion":
                case "Emulsion Mills":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Milss":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Batch Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Continuous Pavers":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Crumb Rubber Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Laboratory Research Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                default:
                  return "servicebbs@bergkampinc.com";
                  break;
              } // switch(product)
            break; // Peru
          case "Russia and CIS":
            switch(product) {
              case "Pavement Preservation":
                switch(category) {
                  case "Truck-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Pre-Mixed Applicators":
                    return "service@bergkampinc.com";
                    break;
                  case "Spreader Box":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                } // switch(category)
                break;
              case "Patching Maintenance":
                switch(category) {
                  case "All-in-one Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Spray Injection Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Truck-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                }
                break;
              case "Emulsion":
                case "Emulsion Mills":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Milss":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Batch Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Continuous Pavers":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Crumb Rubber Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Laboratory Research Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                default:
                  return "servicebbs@bergkampinc.com";
                  break;
              } // switch(product)
            break; // Russia and CIS
          case "Thailand":
            switch(product) {
              case "Pavement Preservation":
                switch(category) {
                  case "Truck-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Pre-Mixed Applicators":
                    return "service@bergkampinc.com";
                    break;
                  case "Spreader Box":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                } // switch(category)
                break;
              case "Patching Maintenance":
                switch(category) {
                  case "All-in-one Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Spray Injection Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Truck-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                }
                break;
              case "Emulsion":
                case "Emulsion Mills":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Milss":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Batch Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Continuous Pavers":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Crumb Rubber Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Laboratory Research Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                default:
                  return "servicebbs@bergkampinc.com";
                  break;
              } // switch(product)
            break; // Thailand
          case "Other":
            switch(product) {
              case "Pavement Preservation":
                switch(category) {
                  case "Truck-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Pavers":
                    return "service@bergkampinc.com";
                    break;
                  case "Pre-Mixed Applicators":
                    return "service@bergkampinc.com";
                    break;
                  case "Spreader Box":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                } // switch(category)
                break;
              case "Patching Maintenance":
                switch(category) {
                  case "All-in-one Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Spray Injection Pothole Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Truck-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Trailer-Mounted Patchers":
                    return "service@bergkampinc.com";
                    break;
                  case "Optional Equipment":
                    return "service@bergkampinc.com";
                    break;
                  default:
                    return "service@bergkampinc.com";
                    break;
                }
                break;
              case "Emulsion":
                case "Emulsion Mills":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Milss":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Batch Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Emulsion Continuous Pavers":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Polymer Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Crumb Rubber Modified Asphalt Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                case "Laboratory Research Plants":
                  return "servicebbs@bergkampinc.com";
                  break;
                default:
                  return "servicebbs@bergkampinc.com";
                  break;
              } // switch(product)
            break; // Other

        }

        return null;
      }
    }); // Document Ready
}); // jQuery
